<template>
  <div class="home">
    <h3 class="common-title">提示词设置</h3>
    <div class="clearfix pb20">
    </div>
    <div class="home-left">
      <el-container>
        <el-main>
          <el-form
            ref="ruleFormRef"
            :model="form"
            :rules="rules"
            label-width="120px"
            class="home-form"
            height="calc(100vh - 300px)"
          >
            <el-form-item
              label="中文提示词"
              prop="prompt"
            >
              <el-input
                type="textarea"
                rows="8"
                v-model="form.prompt"
                :placeholder="configInfo.openai_prompt">
              </el-input>
            </el-form-item>
            <div v-for="language in merchantServerLanguages">
              <el-form-item
                :label="language.name +'提示词'"
                :prop="language.prefix + '_prompt'"
              >
                <el-input
                  type="textarea"
                  rows="8"
                  v-model="form.multilingual_data[language.prefix + '_prompt']"
                  :placeholder="configInfo.multilingual_data['openai_' + language.prefix + '_prompt']">
                </el-input>
              </el-form-item>
            </div>
          </el-form>
        </el-main>
        <el-footer>
          <div class="dialog-footer clearfix pb20">
            <div class="fr">
              <el-button type="primary" @click="submit">确定</el-button>
            </div>
          </div>
        </el-footer>
      </el-container>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from 'vue'
import { ElMessage, genFileId } from 'element-plus'
import { authApi } from '@/api'
import { computed, onMounted } from "@vue/runtime-core";
import Empty from "@/components/Empty.vue";
import store from "@/store";
import { useRoute, useRouter } from "vue-router";
// @ is an alias to /src

export default {
  name: 'Home',
  components: { Empty },
  setup() {
    const route = useRoute()
    const router = useRouter()

    const merchant = computed(() => {
      return store.getters.merchant
    })
    const merchantServerLanguages = computed(() => {
      return merchant.value.server_languages.filter(item => item.is_active)
    })
    const configInfo = computed(() => {
      return store.getters.config
    })
    // 文件 form

    const formRaw = reactive({
      merchant_id: null,
      prompt: null,
      multilingual_data: {},
    })

    const form = reactive(JSON.parse(JSON.stringify(formRaw)))
    const loading = ref(false)
    const rules = ref({})
    const ruleFormRef = ref(null)
    const upload = ref(null)
    const handleExceed = (files) => {
      upload.value.clearFiles()
      const file = files[0]
      file.uid = genFileId()
      upload.value.handleStart(file)
    }
    const handleChange = (file, fileList) => {
      console.log(file)
      form.file = file.raw
    }
    const submit = () => {
      ruleFormRef.value.validate((valid, fields) => {
        if (valid) {
          loading.value = true
          console.log(form)
          authApi.updateMerchantPrompt(form.merchant_id, form).then(res => {
            console.log(res)
            ElMessage({
              message: '保存成功',
              type: 'success',
            })
          }).finally(() => {
            loading.value = false
          })
        }
      })
    }

    const getMerchantPrompt = () => {
      loading.value = true
      authApi.getMerchantPrompt(merchant.value.id).then(res => {
        console.log(res)
        form.merchant_id = merchant.value.id
        form.prompt = res.data.prompt
        form.multilingual_data = res.data.multilingual_data
      }).finally(() => {
        loading.value = false
      })
    }

    onMounted(() => {
      getMerchantPrompt()
    })
    return {
      form,
      loading,
      rules,
      ruleFormRef,
      upload,
      handleExceed,
      submit,
      handleChange,
      merchant,
      merchantServerLanguages,
      configInfo
    }
  }
}
</script>
<style scoped>
.preview-cell {
  white-space: pre-line;
}

.document-tabs .el-tabs__item {
  padding: 32px;
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
}
</style>
